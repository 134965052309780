import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
import {useAuth} from '../../../../auth/core/Auth'
import {UsersQueryResponse} from '../../../user-management/users-list/core/_models'
import axios, {AxiosResponse} from 'axios'
import {getAuth} from '../../../../auth'
import {ToastifyWidget} from '../../../../../../_metronic/partials/widgets'
import {useNavigate, useLocation} from 'react-router-dom'
import moment from 'moment'
interface Step2Props {
  customerId: any
  // asOnDate: String
}

const auth = getAuth()
const API_URL = process.env.REACT_APP_API_URL
const GET_INVESTMENT_URL = `${API_URL}/customer/get_investments?page=1&item_per_page=5000`
const GET_SCHEMES_URL = `${API_URL}/products/get_products`

const GET_TYPE_URL = `${API_URL}/admin/exchange_types`
const GET_SUB_TYPE_URL = `${API_URL}/admin/exchange_sub_types`
const GET_AMC_URL = `${API_URL}/admin/amc`

const config = {
  headers: {
    'Content-type': 'application/json',
    'x-access-token': auth ? auth.data.token : '',
  },
}

const getType = (): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_TYPE_URL}?${auth?.data.id}`, config)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getSubType = (): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_SUB_TYPE_URL}?limit=100`, config)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getAmc = (): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_AMC_URL}?limit=100`, config)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getSchemes = () => {
  return axios.get(`${GET_SCHEMES_URL}?limit=100`, config).then((d) => d.data)
}

const getUserInvestments = (): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_INVESTMENT_URL}`, config)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const Step2: FC<Step2Props> = ({customerId}) => {
  const currentUser = useAuth()
  const location = useLocation()
  const [userInvestments, setUserInvestments] = useState<any[]>([])
  const [schemes, setSchemes] = useState<any[]>([])
  const [types, setTypes] = useState<any[]>([])
  const [subTypes, setSubTypes] = useState<any[]>([])
  const [amcs, setAmcs] = useState<any[]>([])
  const [asOnDateValue, setAsOnDateValue] = useState<String>(moment().format('YYYY-MM-DD'))
  const [loader, setLoader] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAsOnDateValue(event.target.value)
    console.log(event.target.value)
  }

  useEffect(() => {
    if (!auth && location.pathname.startsWith('/users/generate-customer-report')) {
      navigate('/auth/login')
    }
    let temp: any = []
    getUserInvestments()
      .then((d: any) => {
        if (d.data.status === false) {
          ToastifyWidget({messageType: 'error', message: d.data.message, statusCode: d.data.status})
          //setSubmitResponse({head: 'Error', txt: 'Error: ' + res.data.message})
          if (d.data.errorCode == 601) {
            // clear all cookies
            document.cookie.split(';').forEach(function (c) {
              document.cookie = c
                .replace(/^ +/, '')
                .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
            })
            window.localStorage.clear()
            navigate('/auth/login')
          }
        }

        d.data
          .filter((d: any) => d.customerId == customerId && d.status === 'approved')
          .map((d: any) => {
            temp.push(d)
          }, [])
      })
      .then(() => {
        setUserInvestments(temp)
      })
  }, [customerId])

  useEffect(() => {
    getType().then((d: any) => {
      if (d.data.status === false) {
        ToastifyWidget({messageType: 'error', message: d.data.message, statusCode: d.data.status})
        //setSubmitResponse({head: 'Error', txt: 'Error: ' + res.data.message})
        if (d.data.errorCode == 601) {
          // clear all cookies
          document.cookie.split(';').forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, '')
              .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
          })
          window.localStorage.clear()
          navigate('/auth/login')
        }
      }
      setTypes(d.data)
    })
    getSubType().then((d: any) => {
      if (d.data.status === false) {
        ToastifyWidget({messageType: 'error', message: d.data.message, statusCode: d.data.status})
        //setSubmitResponse({head: 'Error', txt: 'Error: ' + res.data.message})
        if (d.data.errorCode == 601) {
          // clear all cookies
          document.cookie.split(';').forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, '')
              .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
          })
          window.localStorage.clear()
          navigate('/auth/login')
        }
      }
      d.data.sort((a: any, b: any) => a.id - b.id)
      setSubTypes(d.data)
    })
    getAmc().then((d: any) => {
      if (d.data.status === false) {
        ToastifyWidget({messageType: 'error', message: d.data.message, statusCode: d.data.status})
        //setSubmitResponse({head: 'Error', txt: 'Error: ' + res.data.message})
        if (d.data.errorCode == 601) {
          // clear all cookies
          document.cookie.split(';').forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, '')
              .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
          })
          window.localStorage.clear()
          navigate('/auth/login')
        }
      }
      d.data.sort((a: any, b: any) => a.id - b.id)
      setAmcs(d.data)
    })
    if (!auth && location.pathname.startsWith('/users/generate-customer-report')) {
      navigate('/auth/login')
    }
    getSchemes().then((d: any) => {
      if (d.data.status === false) {
        ToastifyWidget({messageType: 'error', message: d.data.message, statusCode: d.data.status})
        //setSubmitResponse({head: 'Error', txt: 'Error: ' + res.data.message})
        if (d.data.errorCode == 601) {
          // clear all cookies
          document.cookie.split(';').forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, '')
              .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
          })
          window.localStorage.clear()
          navigate('/auth/login')
        }
      }
      d.data.filter((d: any) => d?.status === 'approved')
      setSchemes(d.data)
    })
  }, [types.length, subTypes.length, amcs.length, schemes.length])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Select your preferences for the report</h2>
        <div className='text-gray-400 fw-bold fs-6'>
          {userInvestments[0]?.applicantGroupName}
          <a href='/dashboard' className='link-primary fw-bolder'>
            {` (${userInvestments[0]?.ucc ? userInvestments[0]?.ucc : '-'})`}
          </a>
        </div>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-6'>
            <label htmlFor='type' className='text-dark fw-bold fs-14'>
              Type
            </label>
            <Field
              as='select'
              className='form-control form-select border border-primary'
              name='type'
              placeholder='Select Type'
              multiple
              size={3}
            >
              {schemes
                ? schemes
                    .filter((d: any) => {
                      for (let i = 0; i < userInvestments.length; i++) {
                        if (d.id == userInvestments[i].schemeId) {
                          // console.log(d)
                          return d
                        }
                        // return false
                      }
                    })
                    // remove duplicates
                    .filter(
                      (d: any, i: any, a: any) =>
                        a.findIndex((t: any) => t.typeId === d.typeId) === i
                    )
                    .map((d: any) => (
                      <option key={d.typeId} value={d.typeId}>
                        {types[d.typeId - 1]?.exchangeType}
                      </option>
                    ))
                : null}
            </Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name='type' />
            </div>
          </div>

          <div className='col-lg-6'>
            <label htmlFor='subType' className='text-dark fw-bold fs-14'>
              Sub-Type
            </label>
            <Field
              as='select'
              className='form-control form-select border border-primary'
              name='subType'
              placeholder='Select Sub-Type'
              multiple
              size={3}
            >
              {schemes
                ? schemes
                    .filter((d: any) => {
                      for (let i = 0; i < userInvestments.length; i++) {
                        if (d.id == userInvestments[i].schemeId) {
                          return d
                        }
                        // return false
                      }
                    })
                    // remove duplicates
                    .filter(
                      (d: any, i: any, a: any) =>
                        a.findIndex((t: any) => t.subTypeId === d.subTypeId) === i
                    )
                    .map((d: any) => (
                      <option key={d.subTypeId} value={d.subTypeId}>
                        {subTypes[d.subTypeId - 1]?.exchangeSubType}
                      </option>
                    ))
                : null}
            </Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name='subType' />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-6'>
            <label htmlFor='amc' className='text-dark fw-bold fs-14'>
              AMC
            </label>
            <Field
              as='select'
              className='form-control form-select border border-primary'
              name='amc'
              placeholder='Select AMC'
              multiple
              size={3}
            >
              {schemes
                ? schemes
                    .filter((d: any) => {
                      for (let i = 0; i < userInvestments.length; i++) {
                        if (d.id == userInvestments[i].schemeId) {
                          return d
                        }
                        // return false
                      }
                    })
                    // remove duplicates
                    .filter(
                      (d: any, i: any, a: any) => a.findIndex((t: any) => t.amcId === d.amcId) === i
                    )
                    .map((d: any) => (
                      <option key={d.amcId} value={d.amcId}>
                        {amcs[d.amcId - 13]?.exchangeAssetManagementCompany}
                      </option>
                    ))
                : null}
            </Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name='amc' />
            </div>
          </div>

          <div className='col-lg-6'>
            <label htmlFor='scheme' className='text-dark fw-bold fs-14'>
              Scheme
            </label>
            <Field
              as='select'
              className='form-control form-select border border-primary'
              name='scheme'
              placeholder='Select Scheme'
              multiple
              size={3}
            >
              {schemes &&
                schemes
                  .filter((d: any) => {
                    for (let i = 0; i < userInvestments.length; i++) {
                      // console.log(userInvestments)
                      if (d.id == userInvestments[i].schemeId) {
                        // console.log(userInvestments[i])
                        return d
                      }
                      // return false
                    }
                  })
                  .map((d: any) => (
                    <option key={d.id} value={d.id}>
                      {d.schemeName}
                    </option>
                  ))}
            </Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name='scheme' />
            </div>
          </div>
        </div>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-12'>
            <label htmlFor='asOnDate' className='text-dark fw-bold fs-14'>
              As on Date
            </label>
            <Field
              type='date'
              className='form-control border border-primary'
              //  value={asOnDateValue}
              name='asOnDate'
              // onChange={handleChange}
              //onChange={(e: any) => alert(e.target.value)}

              //   onChange = {(e: React.ChangeEvent<HTMLInputElement>)=> {
              //     const setAsOnDate = (moment(e.target.value).format('YYYY-MM-DD'));
              //  }}
              max={
                new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)
                  .toISOString()
                  .split('T')[0]
              }
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='asOnDate' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step2}
