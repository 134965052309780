/* eslint-disable jsx-a11y/anchor-is-valid */
// import React, {FC, useState} from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
// //import Carousel from 'react-bootstrap/Carousel';
import {useIntl} from 'react-intl'
// import {toAbsoluteUrl} from '../../../_metronic/helpers/AssetHelpers'
// import {PageTitle} from '../../../_metronic/layout/core'
// import {ListsWidget9, TablesWidget14} from '../../../_metronic/partials/widgets'
import {ToastifyWidget} from '../../../_metronic/partials/widgets/index'
import axios, {AxiosResponse} from 'axios'
import React, {FC, useEffect, useRef, useState} from 'react'
import {useNavigate, useLocation, Link} from 'react-router-dom'
import {getAuth} from '../../modules/auth/core/AuthHelpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {Chart} from 'chart.js'

import {UsersQueryResponse} from '../../modules/apps/user-management/users-list/core/_models'
import {KTSVG} from '../../../_metronic/helpers'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'
import Tooltip from 'react-bootstrap/esm/Tooltip'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 4000, min: 3000},
    items: 5,
  },
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 4,
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 2,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1,
  },
}

const auth = getAuth()
const API_URL = process.env.REACT_APP_API_URL
const GET_VALUE_REPORT = `${API_URL}/customer/get_overall_report_aggregate`

const config = {
  headers: {
    Content_type: 'application/json',
    'x-access-token': auth ? auth.data?.token : '',
  },
}

const DashboardPage: FC = () => {
  let navigate = useNavigate()
  const location = useLocation()
  console.log(location)

  const [portfolioDetails, setPortfolioDetails] = useState<any[]>([])
  const [yValues, setyValues] = useState<any[]>([])
  const [xValues, setxValues] = useState<any[]>([])
  const [totalInvestment, setTotalInvestment] = useState<any>(0)
  const [totalCurrentValue, setTotalCurrentValue] = useState<any>(0)
  const [totalProfit, setTotalProfit] = useState<any>(0)

  const randomColor = () => {
    var letters = '0123456789ABCDEF'
    var color = '#008'
    for (var i = 0; i < 3; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    console.log(color)
    return color
  }

  // set random colors according to number of portfolio
  var barColors: string[] = []
  for (var i = 0; i < portfolioDetails.length; i++) {
    barColors.push(randomColor())
  }

  var ctx: any = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (!auth && location.pathname.startsWith('/dashboard')) {
      navigate('/auth/login')
    }
    axios.get(GET_VALUE_REPORT, config).then((response) => {
      console.log(response.data)
      if (response.data.status === false) {
        ToastifyWidget({
          messageType: 'error',
          message: response.data.message,
          statusCode: response.data.status,
        })
        if (response.data.errorCode == 601) {
          // clear all cookies
          document.cookie.split(';').forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, '')
              .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
          })
          window.localStorage.clear()
          navigate('/auth/login')
        }

        // setSubmitResponse({head: 'Error', txt: 'Error: ' + res.data.message})
      }
      setPortfolioDetails(response.data.data)
      if (response.data.data) {
        const totalInvestments = response.data.data
          .map((item: any) => parseFloat(item.investmentAmount))
          .reduce((prev: number, next: number) => prev + next)

        const totalCurrentValues = response.data.data
          .map((item: any) => parseFloat(item.currentValue))
          .reduce((prev: number, next: number) => prev + next)

        const totalProfits = totalCurrentValues - totalInvestments

        const yValue = [100]
        const xValue = ['Total']
        response.data.data.map((item: any, i: number) => {
          yValue[i] = Number((parseFloat(item.currentValue) / totalCurrentValues) * 100)
          xValue[i] =
            item.schemeName +
            ' (' +
            ((parseFloat(item.currentValue) / totalCurrentValues) * 100).toFixed(2) +
            '%)'
        })
        setyValues(yValue)
        setxValues(xValue)

        setTotalInvestment(totalInvestments.toFixed(2))
        setTotalCurrentValue(totalCurrentValues.toFixed(2))
        setTotalProfit(totalProfits.toFixed(2))
      }
    })
  }, [portfolioDetails?.length])

  useEffect(() => {
    if (ctx.current) {
      let chartStatus = Chart.getChart('myChart')
      if (chartStatus != undefined) {
        chartStatus.destroy()
      }
      ctx = ctx.current.getContext('2d')

      const portfolioChart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: xValues,
          datasets: [
            {
              backgroundColor: barColors,
              data: yValues,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: 'right',
            },
          },
        },
      })
    }
  }, [yValues, xValues])

  return (
    <>
      <div className='content flex-column-fluid pt-0' id='kt_content'>
        <div className='card'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-7 pe-8'>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='row mb-5'>
                      <div className='col-md-12'>
                        <div className='card card-custom card-stretch gutter-b bg-light-primary border border-2'>
                          <div className='card-body'>
                            <a href='#' className='text-primary fs-7 mt-2'>
                              Investments
                            </a>
                            <div className='text-dark-50 fw-bold fs-4 mt-3'>
                              ₹ {Number(totalInvestment).toLocaleString('en-IN')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='row mb-5'>
                      <div className='col-md-12'>
                        <div className='card card-custom card-stretch gutter-b bg-light-primary border border-2'>
                          <div className='card-body'>
                            <a href='#' className='text-primary fs-7 mt-2'>
                              Current Value
                            </a>
                            <div className='text-dark-50 fw-bold fs-4 mt-3'>
                              ₹ {Number(totalCurrentValue).toLocaleString('en-IN')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='row mb-5'>
                      <div className='col-md-12'>
                        <div className='card card-custom card-stretch gutter-b bg-light-primary border border-2'>
                          <div className='card-body'>
                            <a href='#' className='text-primary fs-7 mt-2'>
                              Gain/Loss
                            </a>
                            <div className='text-dark-50 fw-bold fs-4 mt-3'>
                              ₹ {Number(totalProfit).toLocaleString('en-IN')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card card-custom card-stretch gutter-b'>
                      <div className='card-body'>
                        <div className='chartjs-size-monitor'>
                          <div className='chartjs-size-monitor-expand'>
                            <div className=''></div>
                          </div>
                          <div className='chartjs-size-monitor-shrink'>
                            <div className=''></div>
                          </div>
                        </div>
                        <canvas
                          id='myChart'
                          ref={ctx}
                          className='chartjs-render-monitor'
                          style={{display: 'block', width: '600px', height: '300px'}}
                        ></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-md-5 border-start border-2'>
                <div className='container px-2'>
                  <div className='card'>
                    <div className='card-header justify-content-center'>
                      <h2 className='card-title align-items-center flex-column fw-bold text-primary'>
                        Investment / Loan Options
                        <span className='text-muted fw-bold fs-8 pt-1'>
                          Where would you like to invest?
                        </span>
                      </h2>
                    </div>

                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-md-12'>
                          <Link to={'/create-fund-request'} className='card border-2 py-2'>
                            <KTSVG
                              path='/media/icons/duotune/finance/fin0011.svg'
                              className='svg-icon-3x text-primary text-center'
                            />

                            <span className='d-block fw-bold text-center'>
                              <span className='text-dark fw-bolder d-block fs-5'>Mutual Funds</span>
                            </span>
                          </Link>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='card border-2 py-2'>
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                <Tooltip id='tooltip-top'>
                                  <span className='fw-bold'>Coming Soon</span>
                                </Tooltip>
                              }
                            >
                              <span className='text-center'>
                                <KTSVG
                                  path='/media/icons/duotune/finance/fin0033.svg'
                                  className='svg-icon-3x text-center'
                                />
                              </span>
                            </OverlayTrigger>

                            <span className='d-block fw-bold text-center'>
                              <span className='text-dark fw-bolder d-block fs-5'>
                                Stocks & ETFs
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='card border-2 py-2'>
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                <Tooltip id='tooltip-top'>
                                  <span className='fw-bold'>Coming Soon</span>
                                </Tooltip>
                              }
                            >
                              <span className='text-center'>
                                <KTSVG
                                  path='/media/icons/duotune/finance/fin0022.svg'
                                  className='svg-icon-3x text-center'
                                />
                              </span>
                            </OverlayTrigger>

                            <span className='d-block fw-bold text-center'>
                              <span className='text-dark fw-bolder d-block fs-5'>MARS</span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='card border-2 py-2'>
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                <Tooltip id='tooltip-top'>
                                  <span className='fw-bold'>Coming Soon</span>
                                </Tooltip>
                              }
                            >
                              <span className='text-center'>
                                <KTSVG
                                  path='/media/icons/duotune/finance/fin0044.svg'
                                  className='svg-icon-3x text-center'
                                />
                              </span>
                            </OverlayTrigger>

                            <span className='d-block fw-bold text-center'>
                              <span className='text-dark fw-bolder d-block fs-5'>Loan</span>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='card border-2 py-2'>
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                <Tooltip id='tooltip-top'>
                                  <span className='fw-bold'>Coming Soon</span>
                                </Tooltip>
                              }
                            >
                              <span className='text-center'>
                                <KTSVG
                                  path='/media/icons/duotune/finance/fin0055.svg'
                                  className='svg-icon-3x text-center'
                                />
                              </span>
                            </OverlayTrigger>

                            <span className='d-block fw-bold text-center'>
                              <span className='text-dark fw-bolder d-block fs-5'>
                                Public Offers
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='card border-2 py-2'>
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                <Tooltip id='tooltip-top'>
                                  <span className='fw-bold'>Coming Soon</span>
                                </Tooltip>
                              }
                            >
                              <span className='text-center'>
                                <KTSVG
                                  path='/media/icons/duotune/finance/fin0066.svg'
                                  className='svg-icon-3x text-center'
                                />
                              </span>
                            </OverlayTrigger>

                            <span className='d-block fw-bold text-center'>
                              <span className='text-dark fw-bolder d-block fs-5'>Fixed Income</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 className='text-dark mt-8 mb-5 ms-3'>Schemes</h2>

        <div className='card'>
          <div className='card-body'>
            <div className='table-responsive'>
              <table className='table table-bordered'>
                <thead className='border-bottom-1'>
                  <tr>
                    <th scope='col' className='text-muted'>
                      Sr. No.
                    </th>
                    <th scope='col' className='text-muted text-center'>
                      Scheme
                    </th>
                    <th scope='col' className='text-muted text-center'>
                      No. Of Units
                    </th>
                    <th scope='col' className='text-muted text-center'>
                      Inv. Amt. (₹)
                    </th>
                    <th scope='col' className='text-muted text-center'>
                      Current Value (₹)
                    </th>
                    <th scope='col' className='text-muted text-center'>
                      Div P (₹)
                    </th>
                    <th scope='col' className='text-muted text-center'>
                      Unrealised Gain/Loss (₹)
                    </th>
                    <th scope='col' className='text-muted text-center'>
                      Abs. Return (%)
                    </th>
                    <th scope='col' className='text-muted text-center'>
                      Holding (%)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {portfolioDetails &&
                    portfolioDetails.map((item: any, i: number) => (
                      <tr key={i}>
                        <td scope='row' className='text-center pt-6'>
                          {i + 1}
                        </td>
                        <td className='text-start pt-6'>{item.schemeName}</td>
                        <td className='text-center pt-6'>{Number(item.unitsPending).toFixed(2)}</td>
                        <td className='text-center pt-6'>
                          {Number(item.investmentAmount).toFixed(2)}
                        </td>
                        <td className='text-center pt-6'>{Number(item.currentValue).toFixed(2)}</td>
                        <td className='text-center pt-6'>
                          {/* {Number(item.dividendPayout).toFixed(2)} */}
                        </td>
                        <td className='text-center pt-6'>
                          {(item.currentValue - item.investmentAmount).toFixed(2)}
                        </td>
                        <td className='text-center pt-6'>
                          {(
                            ((item.currentValue - item.investmentAmount) / item.investmentAmount) *
                            100
                          ).toFixed(2)}
                        </td>
                        <td className='text-center pt-6'>
                          {((item?.investmentAmount / totalInvestment) * 100).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <th scope='row' className='fw-bolder pt-3'>
                      Total
                    </th>
                    <td className='fw-bolder text-center pt-3'></td>
                    <td className='fw-bolder text-center pt-3'></td>
                    <td className='fw-bolder text-center pt-3'>{totalInvestment || 0}</td>
                    <td className='fw-bolder text-center pt-3'>{totalCurrentValue || 0}</td>
                    <td className='fw-bolder text-center pt-3'></td>
                    <td className='fw-bolder text-center pt-3'>{totalProfit || 0}</td>
                    <td className='fw-bolder text-center pt-3'>
                      {((totalProfit / totalInvestment) * 100).toFixed(2) || 0}
                    </td>
                    <th className='fw-bolder text-center'>100</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* <div className='mt-6 mb-4'>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            renderButtonGroupOutside={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            // autoPlay={this.props.deviceType !== 'mobile' ? true : false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition='all .5'
            transitionDuration={500}
            containerClass='carousel-container'
            removeArrowOnDeviceType={['tablet', 'mobile']}
            // deviceType={this.props.deviceType}
            dotListClass='custom-dot-list-style'
            itemClass='py-7'
          >
            <div>
              <img src='https://picsum.photos/800/300?random=1' alt='1' />
            </div>
            <div>
              <img src='https://picsum.photos/800/300?random=2' alt='2' />
            </div>
            <div>
              <img src='https://picsum.photos/800/300?random=3' alt='3' />
            </div>
            <div>
              <img src='https://picsum.photos/800/300?random=4' alt='4' />
            </div>
            <div>
              <img src='https://picsum.photos/800/300?random=5' alt='5' />
            </div>
          </Carousel>
        </div> */}
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
