import axios, {AxiosResponse} from 'axios'
import {ID, KTSVG, Response} from '../../../../../src/_metronic/helpers'
import {UsersQueryResponse} from '../../../modules/apps/user-management/users-list/core/_models'
import {getAuth} from '../../../../app/modules/auth/core/AuthHelpers'
import React, {FC, useEffect, useState} from 'react'
import {useNavigate, useLocation, Routes, Route} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import {ToastifyWidget} from '../../../../_metronic/partials/widgets'

const auth = getAuth()
const API_URL = process.env.REACT_APP_API_URL
const GET_DETAILS_URL = `${API_URL}/customer/get_investments?page=1&item_per_page=5000`
const config = {
  headers: {
    'Content-type': 'application/json',
    'x-access-token': auth ? auth.data.token : '',
  },
}

const Report: FC = () => {
  const {state}: any = useLocation()
  const {userReport, customerId, filters} = state
  const [keys, setKeys] = useState<any[]>([])
  const [allCustomerDetails, setCustomerDetails] = useState<any>()
  const navigate = useNavigate()
  const currentUser = useAuth()
  const [userReportData, setUserReportData] = useState<any>()

  const applyFilters = Object.entries(filters)
    // remove empty values
    .filter((d: any) => String(d[1]).replace(/\s/g, '').length)
    // put values in string
    .map((d: any) => `${d[0]}=${d[1]}`)
    .join('&')

  const getReport = (): Promise<UsersQueryResponse> => {
    return axios
      .get(`${GET_DETAILS_URL}?${applyFilters}`, config)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }

  useEffect(() => {
    if (!auth) {
      navigate('/auth/login')
    }
    getReport().then((d: any) => {
      // console.log('dsfdsfdsf',d.data)
      setUserReportData(d.data)
      if (d.data.status === false) {
        ToastifyWidget({messageType: 'error', message: d.data.message, statusCode: d.data.status})
        //setSubmitResponse({head: 'Error', txt: 'Error: ' + res.data.message})
        if (d.data.errorCode == 601) {
          // clear all cookies
          document.cookie.split(';').forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, '')
              .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
          })
          window.localStorage.clear()
          navigate('/auth/login')
        }
      }
    })
  }, [])

  // const temp = userReportData.reduce((a: any, {schemeName, ...arr}: any) => {
  //   a[schemeName] = a[schemeName] || {schemeName, arr: new Set()}
  //   a[schemeName].arr.add(arr)
  //   return a
  // }, {})
  // const finalUserReport = Object.values(temp).map((d: any) => ({
  //   schemeName: d.schemeName,
  //   arr: [...d.arr],
  // }))

  const getCustomerDetails = (): Promise<UsersQueryResponse> => {
    return axios
      .get(`${GET_DETAILS_URL}/${customerId}`, config)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }

  useEffect(() => {
    getCustomerDetails().then((d: any) => {
      setCustomerDetails(d.data)
    })

    userReportData.data &&
      userReportData.data.forEach((d: any) => {
        d.transactionDate = new Date(d.transactionDate.split('T')[0]).toLocaleDateString('en-GB')
        d.navDate = new Date(d.navDate.split('T')[0]).toLocaleDateString('en-GB')
      })

    try {
      if (!userReportData?.data.length) {
        currentUser.currentUser?.roleId == 1
          ? navigate('/users/generate-customer-report')
          : navigate('/reports/systematic-status')
        window.alert('No data found for this user')
      } else {
        // setKeys(Object.keys(userReportData?.data[0]))
        // remove schemeName and schemeId from keys
        setKeys(
          Object.keys(userReportData?.data[0]).filter(
            (d: any) => d !== 'schemeName' && d !== 'schemeId' && d !== 'productType'
          )
        )
      }
    } catch (error) {
      currentUser.currentUser?.roleId == 1
        ? navigate('/users/generate-customer-report')
        : navigate('/reports/systematic-status')
      window.alert('No data found for this user')
    }
  }, [])

  return (
    <div className=''>
      {/* back button  */}
      <div className='d-flex justify-content-start mb-1'>
        <button
          className='btn btn-light'
          onClick={() => {
            currentUser.currentUser?.roleId == 1
              ? navigate('/users/generate-customer-report', {
                  state: {
                    customerId,
                    filters,
                  },
                })
              : navigate('/reports/systematic-status', {
                  state: {
                    customerId,
                    filters,
                  },
                })
          }}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-2' />
          Back
        </button>
        <button
          className='btn btn-light'
          onClick={() => {
            // create a letterhead for print with logo and address
            const letterHead = `
              <div class='container-fluid mb-1 bg-white p-2'>
                <div class='row text-center d-flex align-items-center'>
                  <div class='col-md-3 d-flex ms-0 ps-0'>
                    <img src='/media/logos/MF-sewa2.png' alt='MF-Sewa Logo' style="height:75px"/>
                  </div>
                  <div class='col-md-9'>
                    <div class='fs-8'>MF Sewa Kendra</div>
                    <div class='fs-8'>
                      Head Office: MF Sewa, Near Veterinary Hospital, Morinda, Punjab
                    </div>
                    <div class='fs-8'>
                      Our Corporate Office: SCO 333-334, 1st Floor, Cabin No. 208-221, Megabyte Business
                      Centre, Sector 35-B, Chandigarh - 160022
                    </div>
                  </div>
                </div>
              </div>
            `
            const filename = `${allCustomerDetails?.applicantGroupName} - ${new Date(
              filters.asOnDate
            ).toLocaleDateString('en-GB')}`
            const printContents = document.getElementById('printArea')?.innerHTML
            document.body.innerHTML = letterHead + printContents

            document.title = filename
            window.print()
            window.location.reload()
          }}
        >
          Print
          <KTSVG path='/media/icons/duotune/files/fil022.svg' className='svg-icon-2 ps-2' />
        </button>
      </div>

      <div id='printArea'>
        <div className='card card-custom mb-3'>
          <div className='card-header bg-secondary'>
            <div className='card-title'>
              <h3 className='card-label'>
                SIP Status Report
                {/* Transaction Confirmation Statement - As on Date :{' '}
              {new Date(filters.asOnDate).toLocaleDateString('en-GB')} */}
              </h3>
            </div>
          </div>
          {/* <div className='card-body bg-light d-flex row py-3'>
          <div className='col-md-6 text-start'>
            Client Name : {allCustomerDetails?.applicantGroupName || '-'} (
            <span className='text-primary'>
              <u>{allCustomerDetails?.ucc || '-'}</u>
            </span>
            )
          </div>
        </div> */}
        </div>

        <div className='px-2' style={{fontSize: '10px'}}>
          <div className='overflow-scroll'>
            <table className='table table-sm position-relative'>
              <thead className='table-info'>
                <tr className='border border-2 border-secondary'>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Sr. No
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Submission Date
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Investor
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    UCC
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Mode
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Scheme
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Start Date
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    End Date
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    No. of Installments
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Frequency
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Installment Amount (₹)
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Status
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Is Top Up SIP
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Is MARS SIP
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Stop / Cancellation Date
                  </th>
                  {/* <th className='text-center align-middle sticky-top' scope='col'>
                  Brokerage (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Trxn Charges (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Actual Investment (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Stamp Duty (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Exit Load (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Tax (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Bank Payment
                </th> */}
                </tr>
              </thead>
              <tbody>
                {userReportData?.data === undefined ? (
                  <tr>
                    <td colSpan={keys.length + 2} className='text-center'>
                      No data found
                    </td>
                  </tr>
                ) : (
                  <>
                    {userReportData?.data
                      // .filter((d: any) => d.transactionDate < new Date(filters.asOnDate.split('T')[0]))
                      .map((d: any, i: number, arr: any, key: any) => (
                        <>
                          <tr key={i} className='border border-2 bg-white'>
                            <th scope='row' className='text-center align-middle'>
                              {i + 1}
                            </th>
                            <td className='text-center align-middle'>
                              {allCustomerDetails?.applicantName}
                            </td>
                            <td className='text-center align-middle'>{d['ucc']}</td>
                            <td className='text-center align-middle'>{d['isin']}</td>
                            <td className='text-center align-middle'>{d['folioNo']}</td>
                            <td className='text-center align-middle'>{d['transactionDate']}</td>
                            <td className='text-center align-middle'>{d['exchangeSubType']}</td>
                            <td className='text-center align-middle'>
                              {Number(d['investmentAmount']).toLocaleString('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td className='text-center align-middle'>
                              {Number(d['puchaseNavRate']).toLocaleString('en-IN', {
                                minimumFractionDigits: 4,
                                maximumFractionDigits: 4,
                              })}
                            </td>
                            <td className='text-center align-middle'>
                              {Number(d['unitsAssigned']).toLocaleString('en-IN', {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })}
                            </td>
                            <td className='text-center align-middle'>{d['navDate']}</td>
                            <td className='text-center align-middle'>{d['txnDays']}</td>
                            <td className='text-center align-middle'>
                              {Number(d['currentRate']).toLocaleString('en-IN', {
                                minimumFractionDigits: 4,
                                maximumFractionDigits: 4,
                              })}
                            </td>
                            <td className='text-center align-middle'>
                              {Number(d['currentValue']).toLocaleString('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td className='text-center align-middle'>
                              {Number(d['currentValue']).toLocaleString('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            {/* <td className='text-center align-middle'>
                            {Number(d['CAGR']).toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className='text-center align-middle'>
                            {Number(d['simpleReturn']).toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className='text-center align-middle'>
                            {Number(d['simpleReturn']).toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className='text-center align-middle'>
                            {Number(d['simpleReturn']).toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className='text-center align-middle'>
                            {Number(d['simpleReturn']).toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className='text-center align-middle'>
                            {Number(d['simpleReturn']).toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className='text-center align-middle'>
                            {Number(d['simpleReturn']).toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td> */}
                          </tr>
                        </>
                      ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className='row'>
            <div className='col-sm-12 py-3 px-6 border-top-3 border-dark text-dark bg-white'>
              <h5>
                <b>Note :</b>
              </h5>
              <ul>
                <li>
                  SIP Status "Pending" means the SIP is not registered on Exchange by MF Sewa
                  Kendra.
                </li>
                <li>
                  For Physical SIP, "Reg. No." and "Submission Date" is not available, hence
                  displayed "-".
                </li>
                <li>
                  The MF SIP figure displayed above is the Net Investable amount after adjusting
                  Stamp Duty.
                </li>
                <li>
                  This Report is Strictly Private and Confidential only for clients of MF Sewa
                  Kendra. The information given above is correct and to the best of our knowledge.
                  For any discrepancy on the same contact the nearest Office of MF Sewa Kendra.
                </li>
                <li>
                  Mode for SIP registration via MF Sewa Account is 'Online' while for physical
                  applications the mode is 'Physical'.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const SystematicStatusReport = () => (
  <Routes>
    <Route element={<Report />}>
      {/* <Route path='users/generate-customer-report/:id' element={<Report />} /> */}
      <Route index element={<Report />} />
    </Route>
  </Routes>
)

export {SystematicStatusReport}
