import axios, {AxiosResponse} from 'axios'
import React, {FC, useEffect, useRef, useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {getAuth} from '../../auth/core/AuthHelpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {Chart} from 'chart.js'

import {UsersQueryResponse} from '../../../modules/apps/user-management/users-list/core/_models'

const auth = getAuth()
const API_URL = process.env.REACT_APP_API_URL
const GET_PORTFOLIO_URL = `${API_URL}/customer/get_portfolio`

const config = {
  headers: {
    Content_type: 'application/json',
    'x-access-token': auth ? auth.data?.token : '',
  },
}

const Portfolio: FC = () => {
  let navigate = useNavigate()
  const location = useLocation()
  console.log(location)
  const [portfolioDetails, setPortfolioDetails] = useState<any[]>([])
  const [yValues, setyValues] = useState<any[]>([])
  const [xValues, setxValues] = useState<any[]>([])
  const [totalInvestment, setTotalInvestment] = useState<any>(0)

  var barColors = ['#f8b500', '#d83b01', '#00FF00', '#0000FF']

  var ctx: any = useRef<HTMLCanvasElement>(null)

  const getPortfolioDetails = (): Promise<UsersQueryResponse> => {
    return axios
      .get(`${GET_PORTFOLIO_URL}`, config)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }

  useEffect(() => {
    if (!auth && location.pathname.startsWith('/portfolio')) {
      navigate('/auth/login')
    }
    axios.get(GET_PORTFOLIO_URL, config).then((response) => {
      setPortfolioDetails(response.data.data)

      if (response.data.data) {
        const totalInvestments = response.data.data
          .map((item: any) => parseFloat(item.totalInvestment))
          .reduce((prev: number, next: number) => prev + next)
        // console.log(totalInvestments, typeof totalInvestments)
        const yValue = [100]
        const xValue = ['Total']
        response.data.data.map((item: any, i: number) => {
          yValue[i] = Number((parseFloat(item.totalInvestment) / totalInvestments) * 100)
          xValue[i] =
            item.exchangeType +
            ' (' +
            ((parseFloat(item.totalInvestment) / totalInvestments) * 100).toFixed(2) +
            '%)'
        })
        setyValues(yValue)
        setxValues(xValue)

        setTotalInvestment(totalInvestments.toFixed(2))
      }
    })
  }, [portfolioDetails?.length])

  useEffect(() => {
    if (ctx.current) {
      let chartStatus = Chart.getChart('myChart')
      if (chartStatus != undefined) {
        chartStatus.destroy()
      }
      ctx = ctx.current.getContext('2d')

      const portfolioChart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: xValues,
          datasets: [
            {
              backgroundColor: barColors,
              data: yValues,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      })
    }
  }, [yValues, xValues])

  return (
    <>
      <PageTitle breadcrumbs={[]}>Portfolio</PageTitle>
      <div className='content flex-column-fluid pt-0' id='kt_content'>
        {/* <h2 className='text-dark mb-5 ms-3'>Portfolio Overview</h2> */}

        <div className='card'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-5'>
                {portfolioDetails
                  ? portfolioDetails.map((item: any, i: number) => (
                      <div className='row mb-5' key={i}>
                        <div className='col-md-10'>
                          <div className='card card-custom card-stretch gutter-b bg-light-primary border border-2'>
                            <div className='card-body'>
                              <a href='#' className='text-primary font-weight-bold fs-6 mt-2'>
                                {item.exchangeType}
                              </a>
                              <div className='text-dark-50 font-weight-bold fs-1 mt-3'>
                                &#8377; {item.totalInvestment.toFixed(0)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : 'No data was found. Please refresh the page to try again.'}
              </div>

              <div className='col-md-7'>
                <div className='card card-custom card-stretch gutter-b'>
                  <div className='card-body'>
                    <div className='chartjs-size-monitor'>
                      <div className='chartjs-size-monitor-expand'>
                        <div className=''></div>
                      </div>
                      <div className='chartjs-size-monitor-shrink'>
                        <div className=''></div>
                      </div>
                    </div>
                    <canvas
                      id='myChart'
                      ref={ctx}
                      className='chartjs-render-monitor'
                      style={{display: 'block', width: '400px', height: '380px'}}
                    ></canvas>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 className='text-dark mt-8 mb-5 ms-3'>Asset Class</h2>

        <div className='card'>
          <div className='card-body'>
            <div className='table-responsive'>
              <table className='table table-bordered'>
                <thead className='border-bottom-1'>
                  <tr>
                    <th scope='col' className='text-muted'>
                      Asset Class Name
                    </th>
                    <th scope='col' className='text-muted text-center'>
                      Current Value (&#8377;)
                    </th>
                    <th scope='col' className='text-muted text-center'>
                      Holding (%)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {portfolioDetails &&
                    portfolioDetails.map((item: any, i: number) => (
                      <tr key={i}>
                        <td scope='row' className='pt-6'>
                          <svg width='20' height='15' className='ms-2'>
                            <rect
                              width='20'
                              height='15'
                              style={{fill: barColors[i], stroke: 'black', strokeWidth: 1}}
                            />
                          </svg>
                          &ensp; {item.exchangeType}
                        </td>
                        <td className='text-center pt-6'>{item.totalInvestment}</td>
                        <td className='text-center pt-6'>
                          {((item?.totalInvestment / totalInvestment) * 100).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <th scope='row' className='fw-bolder ps-11 pt-3'>
                      Total
                    </th>
                    <th className='fw-bolder text-center'>
                      {totalInvestment ? totalInvestment : 0}
                    </th>
                    <th className='fw-bolder text-center'>100</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Portfolio}
