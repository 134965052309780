/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect, useRef, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import IdleTimer from './IdleTimer'
import {ValuationReport} from '../modules/apps/user-report/valuation/Report'
import {TransactionReport} from '../modules/apps/user-report/transaction/Report'
import {AUMReport} from '../modules/apps/user-report/aum/Report'
import {BusinessReport} from '../modules/apps/user-report/business/Report'
import {SystematicStatusReport} from '../../app/pages/reports/systematic-status/Report'
import {ProfitLossReport} from '../../app/pages/reports/profit-loss/Report'
import {ELSSReport} from '../../app/pages/reports/elss/Report'
import {SystematicPerformanceReport} from '../../app/pages/reports/systematic-performance/Report'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser, logout} = useAuth()
  const [isTimeout, setIsTimeout] = useState(false)
  // const logoutTimerIdRef = useRef(null)

  useEffect(() => {
    // const autoLogout =()=>{

    //   if(document.visibilityState === 'hidden'){

    //     const timeOut = window.setTimeout(logoutUser, 5 * 60 * 1000);
    //     logoutTimerIdRef.current = timeOutId ;
    //   } else
    //   {
    //     window.clearTimeout(logoutTimerIdRef.current)

    //   }

    //   }

    //   document.addEventListener('visibilitychange', autoLogout )

    const timer = new IdleTimer({
      timeout: 1, //expire after 10 minutes
      onTimeout: () => {
        if (currentUser) {
          setIsTimeout(true)
          logout()
          window.alert('You have been logged out due to inactivity. Please login again.')
          document.cookie.split(';').forEach((c) => {
            document.cookie = c
              .replace(/^ +/, '')
              .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
          })
        }
      },
      onExpired: () => {
        if (currentUser) {
          setIsTimeout(true)
          logout()
          window.alert('Your session has been expired. Please login again.')
          document.cookie.split(';').forEach((c) => {
            document.cookie = c
              .replace(/^ +/, '')
              .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
          })
        }
      },
    })

    return () => {
      timer.cleanUp()
    }
  }, [])

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser && !isTimeout ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route path='reports/current-holdings/show' element={<ValuationReport />} />
              <Route path='reports/transactions/show/*' element={<TransactionReport />} />
              <Route path='reports/aum/show' element={<AUMReport />} />
              <Route path='reports/business/show' element={<BusinessReport />} />
              <Route path='reports/systematic-status/show' element={<SystematicStatusReport />} />
              <Route path='reports/profit-loss/show' element={<ProfitLossReport />} />
              <Route path='reports/elss/show' element={<ELSSReport />} />
              <Route
                path='reports/systematic-performance/show'
                element={<SystematicPerformanceReport />}
              />

              <Route path='users/generate-customer-report/:id' element={<ValuationReport />} />
              <Route path='users/generate-transaction-report/:id' element={<TransactionReport />} />
              <Route path='users/generate-aum-report/' element={<AUMReport />} />
              <Route path='users/generate-business-report/:id' element={<BusinessReport />} />

              <Route path='auth/*' element={<AuthPage timeout={isTimeout} />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='*' element={<Navigate to='/auth' />} />
              <Route path='auth/*' element={<AuthPage timeout={isTimeout} />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
