import axios, {AxiosResponse} from 'axios'
import {ID, KTSVG, Response} from '../../../../../src/_metronic/helpers'
import {UsersQueryResponse} from '../../../modules/apps/user-management/users-list/core/_models'
import {getAuth} from '../../../../app/modules/auth/core/AuthHelpers'
import React, {FC, useEffect, useState} from 'react'
import {useNavigate, useLocation, Routes, Route} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import {ToastifyWidget} from '../../../../_metronic/partials/widgets'

const auth = getAuth()
const API_URL = process.env.REACT_APP_API_URL
const GET_DETAILS_URL = `${API_URL}/customer/get_customer_details`
const config = {
  headers: {
    'Content-type': 'application/json',
    'x-access-token': auth ? auth.data.token : '',
  },
}

const Report: FC = () => {
  const {state}: any = useLocation()
  const {userReport, customerId, filters} = state
  const [keys, setKeys] = useState<any[]>([])
  const [allCustomerDetails, setCustomerDetails] = useState<any>()
  const navigate = useNavigate()
  const currentUser = useAuth()
  const [userReportData, setUserReportData] = useState<any>()

  const applyFilters = Object.entries(filters)
    // remove empty values
    .filter((d: any) => String(d[1]).replace(/\s/g, '').length)
    // put values in string
    .map((d: any) => `${d[0]}=${d[1]}`)
    .join('&')

  const getReport = (): Promise<UsersQueryResponse> => {
    return axios
      .get(`${GET_DETAILS_URL}?${applyFilters}`, config)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }

  useEffect(() => {
    if (!auth) {
      navigate('/auth/login')
    }
    getReport().then((d: any) => {
      // console.log('dsfdsfdsf',d.data)
      setUserReportData(d.data)
      if (d.data.status === false) {
        ToastifyWidget({messageType: 'error', message: d.data.message, statusCode: d.data.status})
        //setSubmitResponse({head: 'Error', txt: 'Error: ' + res.data.message})
        if (d.data.errorCode == 601) {
          // clear all cookies
          document.cookie.split(';').forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, '')
              .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
          })
          window.localStorage.clear()
          navigate('/auth/login')
        }
      }
    })
  }, [])

  // const temp = userReportData.reduce((a: any, {schemeName, ...arr}: any) => {
  //   a[schemeName] = a[schemeName] || {schemeName, arr: new Set()}
  //   a[schemeName].arr.add(arr)
  //   return a
  // }, {})
  // const finalUserReport = Object.values(temp).map((d: any) => ({
  //   schemeName: d.schemeName,
  //   arr: [...d.arr],
  // }))

  const getCustomerDetails = (): Promise<UsersQueryResponse> => {
    return axios
      .get(`${GET_DETAILS_URL}/${customerId}`, config)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }

  useEffect(() => {
    getCustomerDetails().then((d: any) => {
      setCustomerDetails(d.data)
    })

    userReportData.data &&
      userReportData.data.forEach((d: any) => {
        d.transactionDate = new Date(d.transactionDate.split('T')[0]).toLocaleDateString('en-GB')
        d.navDate = new Date(d.navDate.split('T')[0]).toLocaleDateString('en-GB')
      })

    try {
      if (!userReportData?.data.length) {
        currentUser.currentUser?.roleId == 1
          ? navigate('/users/generate-elss-report')
          : navigate('/reports/elss')
        window.alert('No data found for this user')
      } else {
        // setKeys(Object.keys(userReportData?.data[0]))
        // remove schemeName and schemeId from keys
        setKeys(
          Object.keys(userReportData?.data[0]).filter(
            (d: any) => d !== 'schemeName' && d !== 'schemeId' && d !== 'productType'
          )
        )
      }
    } catch (error) {
      currentUser.currentUser?.roleId == 1
        ? navigate('/users/generate-elss-report')
        : navigate('/reports/elss')
      window.alert('No data found for this user')
    }
  }, [])

  return (
    <div className=''>
      {/* back button  */}
      <div className='d-flex justify-content-start mb-1'>
        <button
          className='btn btn-light'
          onClick={() => {
            currentUser.currentUser?.roleId == 1
              ? navigate('/users/generate-elss-report', {
                  state: {
                    customerId,
                    filters,
                  },
                })
              : navigate('/reports/elss', {
                  state: {
                    customerId,
                    filters,
                  },
                })
          }}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-2' />
          Back
        </button>
      </div>

      <div className='card card-custom mb-3'>
        <div className='card-header bg-secondary'>
          <div className='card-title'>
            <h3 className='card-label'>
              ELSS Status Report - As on Date :{' '}
              {new Date(filters.asOnDate).toLocaleDateString('en-GB')}
            </h3>
          </div>
        </div>
        {/* <div className='card-body bg-light d-flex row py-3'>
          <div className='col-md-6 text-start'>
            Client Name : {allCustomerDetails?.applicantGroupName || '-'} (
            <span className='text-primary'>
              <u>{allCustomerDetails?.ucc || '-'}</u>
            </span>
            )
          </div>
        </div> */}
      </div>

      <div className='px-2' style={{fontSize: '10px'}}>
        <div className='overflow-scroll'>
          <table className='table table-sm position-relative'>
            <thead className='table-info'>
              <tr className='border border-2 border-secondary'>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Sr. No
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Scheme
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Sub Type
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Fund Type
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Amount (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Div. Reinvst. (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  No. of Units
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Current Value (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Lock Free Units
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Lock Free Value (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Lock-in Units
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Lock-in Value (₹)
                </th>
                {/* <th className='text-center align-middle sticky-top' scope='col'>
                  Is Top Up SIP
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Is MARS SIP
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Stop / Cancellation Date
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Brokerage (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Trxn Charges (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Actual Investment (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Stamp Duty (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Exit Load (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Tax (₹)
                </th>
                <th className='text-center align-middle sticky-top' scope='col'>
                  Bank Payment
                </th> */}
              </tr>
            </thead>
            <tbody>
              {userReportData?.data === undefined ? (
                <tr>
                  <td colSpan={keys.length + 2} className='text-center'>
                    No data found
                  </td>
                </tr>
              ) : (
                <>
                  {userReportData?.data
                    // .filter((d: any) => d.transactionDate < new Date(filters.asOnDate.split('T')[0]))
                    .map((d: any, i: number, arr: any, key: any) => (
                      <>
                        <tr key={i} className='border border-2 bg-white'>
                          <th scope='row' className='text-center align-middle'>
                            {i + 1}
                          </th>
                          <td className='text-center align-middle'>
                            {allCustomerDetails?.applicantName}
                          </td>
                          <td className='text-center align-middle'>{d['ucc']}</td>
                          <td className='text-center align-middle'>{d['isin']}</td>
                          <td className='text-center align-middle'>{d['folioNo']}</td>
                          <td className='text-center align-middle'>{d['transactionDate']}</td>
                          <td className='text-center align-middle'>{d['exchangeSubType']}</td>
                          <td className='text-center align-middle'>
                            {Number(d['investmentAmount']).toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className='text-center align-middle'>
                            {Number(d['puchaseNavRate']).toLocaleString('en-IN', {
                              minimumFractionDigits: 4,
                              maximumFractionDigits: 4,
                            })}
                          </td>
                          <td className='text-center align-middle'>
                            {Number(d['unitsAssigned']).toLocaleString('en-IN', {
                              minimumFractionDigits: 3,
                              maximumFractionDigits: 3,
                            })}
                          </td>
                          <td className='text-center align-middle'>{d['navDate']}</td>
                          <td className='text-center align-middle'>{d['txnDays']}</td>
                          {/*<td className='text-center align-middle'>
                            {Number(d['currentRate']).toLocaleString('en-IN', {
                              minimumFractionDigits: 4,
                              maximumFractionDigits: 4,
                            })}
                          </td>
                          <td className='text-center align-middle'>
                            {Number(d['currentValue']).toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className='text-center align-middle'>
                            {Number(d['currentValue']).toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                           <td className='text-center align-middle'>
                            {Number(d['CAGR']).toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className='text-center align-middle'>
                            {Number(d['simpleReturn']).toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className='text-center align-middle'>
                            {Number(d['simpleReturn']).toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className='text-center align-middle'>
                            {Number(d['simpleReturn']).toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className='text-center align-middle'>
                            {Number(d['simpleReturn']).toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className='text-center align-middle'>
                            {Number(d['simpleReturn']).toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className='text-center align-middle'>
                            {Number(d['simpleReturn']).toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td> */}
                        </tr>
                      </>
                    ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className='row'>
          <div className='col-sm-12 py-3 px-6 border-top-3 border-dark text-dark bg-white'>
            <h5>
              <b>Note :</b>
            </h5>
            <ul>
              <li>Closed ended scheme's unit will be locked till maturity date.</li>
              <li>
                Investments in Mutual Fund Schemes eligible for the tax benefit under Section 80C of
                the Income Tax Act, 1961 are listed in this report.
              </li>
              <li>
                This report is for reference purposes only and we recommend consulting your tax
                adviser / tax practitioner to determine your Income Tax liability before filing
                income tax returns. Please refer to the DP Holdings in DP Statements (Reports) for
                actual holdings as on date.
              </li>
              <li>
                This Report is Strictly Private and Confidential only for clients of MF Sewa Kendra.
                The information given above is correct and to the best of our knowledge. For any
                discrepancy on the same contact the nearest Office of MF Sewa Kendra.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const ELSSReport = () => (
  <Routes>
    <Route element={<Report />}>
      {/* <Route path='users/generate-customer-report/:id' element={<Report />} /> */}
      <Route index element={<Report />} />
    </Route>
  </Routes>
)

export {ELSSReport}
