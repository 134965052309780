import axios, {AxiosResponse} from 'axios'
import {ID, KTSVG, Response} from '../../../../../../src/_metronic/helpers'
import {UsersQueryResponse} from '../../user-management/users-list/core/_models'
import {getAuth} from '../../../../../app/modules/auth/core/AuthHelpers'
import React, {FC, useEffect, useState} from 'react'
import {useNavigate, useLocation, Routes, Route} from 'react-router-dom'
import {useAuth} from '../../../auth/core/Auth'
import {ToastifyWidget} from '../../../../../_metronic/partials/widgets'

const auth = getAuth()
const API_URL = process.env.REACT_APP_API_URL
const GET_DETAILS_URL = `${API_URL}/customer/get_customer_details`
const config = {
  headers: {
    'Content-type': 'application/json',
    'x-access-token': auth ? auth.data.token : '',
  },
}

const Report: FC = () => {
  const {state}: any = useLocation()
  const {userReport, customerId, filters} = state
  const [keys, setKeys] = useState<any[]>([])
  const [allCustomerDetails, setCustomerDetails] = useState<any>()
  const navigate = useNavigate()
  const currentUser = useAuth()
  const [userReportData, setUserReportData] = useState<any>()

  const applyFilters = Object.entries(filters)
    // remove empty values
    .filter((d: any) => String(d[1]).replace(/\s/g, '').length)
    // put values in string
    .map((d: any) => `${d[0]}=${d[1]}`)
    .join('&')

  const getReport = (): Promise<UsersQueryResponse> => {
    return axios
      .get(`${GET_DETAILS_URL}?${applyFilters}`, config)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }

  useEffect(() => {
    if (!auth) {
      navigate('/auth/login')
    }
    getReport().then((d: any) => {
      // console.log('dsfdsfdsf',d.data)
      setUserReportData(d.data)
      if (d.data.status === false) {
        ToastifyWidget({messageType: 'error', message: d.data.message, statusCode: d.data.status})
        //setSubmitResponse({head: 'Error', txt: 'Error: ' + res.data.message})
        if (d.data.errorCode == 601) {
          // clear all cookies
          document.cookie.split(';').forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, '')
              .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
          })
          window.localStorage.clear()
          navigate('/auth/login')
        }
      }
    })
  }, [])

  const getCustomerDetails = (): Promise<UsersQueryResponse> => {
    return axios
      .get(`${GET_DETAILS_URL}/${customerId}`, config)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }

  useEffect(() => {
    getCustomerDetails().then((d: any) => {
      setCustomerDetails(d.data)
    })

    userReportData.data &&
      userReportData.data.forEach((d: any) => {
        d.transactionDate = new Date(d.transactionDate.split('T')[0]).toLocaleDateString('en-GB')
        d.navDate = new Date(d.navDate.split('T')[0]).toLocaleDateString('en-GB')
      })

    try {
      if (!userReportData?.data.length) {
        currentUser.currentUser?.roleId == 1
          ? navigate('/users/generate-customer-report')
          : navigate('/reports/current-holdings')
        window.alert('No data found for this user')
      } else {
        // setKeys(Object.keys(userReportData?.data[0]))
        // remove schemeName and schemeId from keys
        setKeys(
          Object.keys(userReportData?.data[0]).filter(
            (d: any) => d !== 'schemeName' && d !== 'schemeId' && d !== 'productType'
          )
        )
      }
    } catch (error) {
      currentUser.currentUser?.roleId == 1
        ? navigate('/users/generate-customer-report')
        : navigate('/reports/current-holdings')
      window.alert('No data found for this user')
    }
  }, [])

  return (
    <div className=''>
      {/* back button  */}
      <div className='d-flex justify-content-start mb-1'>
        <button
          className='btn btn-light'
          onClick={() => {
            currentUser.currentUser?.roleId == 1
              ? navigate('/users/generate-customer-report', {
                  state: {
                    customerId,
                    filters,
                  },
                })
              : navigate('/reports/current-holdings', {
                  state: {
                    customerId,
                    filters,
                  },
                })
          }}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-2' />
          Back
        </button>
        <button
          className='btn btn-light'
          onClick={() => {
            // create a letterhead for print with logo and address
            const letterHead = `
              <div class='container-fluid mb-1 bg-white p-2'>
                <div class='row text-center d-flex align-items-center'>
                  <div class='col-md-3 d-flex ms-0 ps-0'>
                    <img src='/media/logos/MF-sewa2.png' alt='MF-Sewa Logo' style="height:75px"/>
                  </div>
                  <div class='col-md-9'>
                    <div class='fs-8'>MF Sewa Kendra</div>
                    <div class='fs-8'>
                      Head Office: MF Sewa, Near Veterinary Hospital, Morinda, Punjab
                    </div>
                    <div class='fs-8'>
                      Our Corporate Office: SCO 333-334, 1st Floor, Cabin No. 208-221, Megabyte Business
                      Centre, Sector 35-B, Chandigarh - 160022
                    </div>
                  </div>
                </div>
              </div>
            `
            const filename = `${allCustomerDetails?.applicantGroupName} - ${new Date(
              filters.asOnDate
            ).toLocaleDateString('en-GB')}`
            const printContents = document.getElementById('printArea')?.innerHTML
            document.body.innerHTML = letterHead + printContents

            document.title = filename
            window.print()
            window.location.reload()
          }}
        >
          Print
          <KTSVG path='/media/icons/duotune/files/fil022.svg' className='svg-icon-2 ps-2' />
        </button>
      </div>

      <div id='printArea'>
        <div className='card card-custom mb-3'>
          <div className='card-header bg-secondary'>
            <div className='card-title'>
              <h3 className='card-label'>
                Business Insight Report -{' '}
                {filters.reportFor
                  .replace(/([A-Z])/g, ' $1')
                  .charAt(0)
                  .toUpperCase() + filters.reportFor.replace(/([A-Z])/g, ' $1').slice(1)}{' '}
                Wise
              </h3>
            </div>
          </div>
          {/* <div className='card-body bg-light d-flex row py-3'>
          <div className='col-md-6 text-start'>
            Client Name : {allCustomerDetails?.applicantGroupName || '-'} (
            <span className='text-primary'>
              <u>{allCustomerDetails?.ucc || '-'}</u>
            </span>
            )
          </div>
        </div> */}
        </div>

        <div className='px-2' style={{fontSize: '10px'}}>
          <div className='overflow-scroll'>
            <table className='table table-sm position-relative'>
              <thead className='table-info'>
                <tr className='border border-2 border-secondary'>
                  <th className='text-center align-middle sticky-top' scope='col' rowSpan={3}>
                    Sr. No
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col' rowSpan={3}>
                    Partner/Employee
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col' rowSpan={3}>
                    Group
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col' rowSpan={3}>
                    Investors
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col' colSpan={12}>
                    Investment
                  </th>
                </tr>
                <tr className='border border-2 border-secondary'>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    MF Net Sales (FY) (₹)
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Total Live SIP Amount (FY) (₹)
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Sales in Tax Plans (FY) (₹)
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col' colSpan={8}>
                    AS ON {new Date().toLocaleDateString('en-GB')}
                  </th>
                </tr>
                <tr className='border border-2 border-secondary'>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    2021-2022
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    2021-2022
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    2021-2022
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    No. of Active E-Wealth Account
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Live SIP Amount (₹)
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Latest SIP Started on
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Live SIP Amount (₹) in Tax Plans - ELSS
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Equity AUM (₹)
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Total AUM (₹)
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Equity AUM (% of Total AUM)
                  </th>
                  <th className='text-center align-middle sticky-top' scope='col'>
                    Total MF Investment (₹)
                  </th>
                </tr>
              </thead>
              <tbody>
                {userReportData?.data === undefined ? (
                  <tr>
                    <td colSpan={keys.length + 2} className='text-center'>
                      No data found
                    </td>
                  </tr>
                ) : (
                  <>
                    {userReportData?.data
                      // .filter((d: any) => d.transactionDate < new Date(filters.asOnDate.split('T')[0]))
                      .map((d: any, i: number, arr: any, key: any) => (
                        <React.Fragment key={i}>
                          <tr className='border border-2 bg-white'>
                            <th scope='row' className='text-center align-middle'>
                              {i + 1}
                            </th>
                            <td className='text-center align-middle'>
                              {allCustomerDetails?.applicantName}
                            </td>
                            <td className='text-center align-middle'>{d['ucc']}</td>
                            <td className='text-center align-middle'>{d['isin']}</td>
                            <td className='text-center align-middle'>{d['folioNo']}</td>
                            <td className='text-center align-middle'>{d['folioNo']}</td>
                            <td className='text-center align-middle'>{d['transactionDate']}</td>
                            <td className='text-center align-middle'>{d['exchangeSubType']}</td>
                            <td className='text-center align-middle'>
                              {Number(d['investmentAmount']).toLocaleString('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td className='text-center align-middle'>
                              {Number(d['puchaseNavRate']).toLocaleString('en-IN', {
                                minimumFractionDigits: 4,
                                maximumFractionDigits: 4,
                              })}
                            </td>
                            <td className='text-center align-middle'>
                              {Number(d['unitsAssigned']).toLocaleString('en-IN', {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })}
                            </td>
                            <td className='text-center align-middle'>
                              {Number(d['currentRate']).toLocaleString('en-IN', {
                                minimumFractionDigits: 4,
                                maximumFractionDigits: 4,
                              })}
                            </td>
                            <td className='text-center align-middle'>
                              {Number(d['currentValue']).toLocaleString('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td className='text-center align-middle'>
                              {Number(d['CAGR']).toLocaleString('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td className='text-center align-middle'>
                              {Number(d['simpleReturn']).toLocaleString('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </>
                )}
                <tr className='border border-2 bg-secondary fw-bold'>
                  <th scope='row' className='text-center align-middle' colSpan={4}>
                    Total
                  </th>
                  <th className='text-center align-middle'>0</th>
                  <th className='text-center align-middle'>84638</th>
                  <th className='text-center align-middle'>126748</th>
                  <th className='text-center align-middle'>45</th>
                  <th className='text-center align-middle'>84638</th>
                  <th className='text-center align-middle'></th>
                  <th className='text-center align-middle'>43294</th>
                  <th className='text-center align-middle'>3226748</th>
                  <th className='text-center align-middle'>6152748</th>
                  <th className='text-center align-middle'></th>
                  <th className='text-center align-middle'>43294</th>
                </tr>

                <tr className='border border-2 bg-secondary fw-bold'>
                  <th scope='row' className='text-center align-middle' colSpan={4}>
                    Grand Total
                  </th>
                  <th className='text-center align-middle'>0</th>
                  <th className='text-center align-middle'>84638</th>
                  <th className='text-center align-middle'>126748</th>
                  <th className='text-center align-middle'>45</th>
                  <th className='text-center align-middle'>84638</th>
                  <th className='text-center align-middle'></th>
                  <th className='text-center align-middle'>43294</th>
                  <th className='text-center align-middle'>3226748</th>
                  <th className='text-center align-middle'>6152748</th>
                  <th className='text-center align-middle'></th>
                  <th className='text-center align-middle'>43294</th>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div className='row'>
          <div className='col-sm-12 py-3 px-6 border-top-3 border-dark text-dark bg-white'>
            <h5>
              <b>Note :</b>
            </h5>
            <ol type='1'>
              <li>Switch In and Switch Out indicates AMC Switch Transactions.</li>
              <li>Purchase(SWI) and Redemption(SWI) indicated MF Sewa Switch Transactions.</li>
              <li>Please note that Debit/Payment date may vary from your bank statement.</li>
            </ol>
          </div>
        </div> */}
        </div>
      </div>
    </div>
  )
}

const BusinessReport = () => (
  <Routes>
    <Route element={<Report />}>
      <Route path='users/generate-customer-report/:id' element={<Report />} />
      <Route index element={<Report />} />
    </Route>
  </Routes>
)

export {BusinessReport}
